import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Ball from "../components/ball"
import './index.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Testicle Simulator" />
    <div className={"main"}>
      <Ball />
      <Ball />
    </div>
  </Layout>
)

export default IndexPage
